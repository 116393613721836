import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";

import { Link } from "react-router-dom";
import logo from "../../images/footer-logo.svg";

const Wrapper = styled.div`
  padding: 15px 30px;
  background: #fafafa;

  a {
    text-decoration: none;
    color: #fff;
  }
  .nav-item {
    border-right: 1px solid #bea48e;
    color: #bea48e;
    font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 21px;




  }
  .contact {
    color: #bea48e;
    font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 21px;
  }

  @media only screen and (max-width: 767px) {
    .footer-items {
      flex-direction: column;
      border-left: 1px solid #bea48e;
    }
    .nav-item {
      border-right: none;
      font-size: 14px;
      padding: 5px;
    }
    .footer-logo img {
      margin: auto;
    }
  }
`;
const Footer = () => {
  const navitems = [
    { name: " B2 Homeowner Login", to: "login" },
    { name: " Join our B2 Affiliates", to: "join" },
  ];
  return (
    <Wrapper>
      <Row className="align-items-center">
        <Col
          xs={4}
          md={2}
          className="d-flex align-items-center px-4 footer-logo"
        >
          <img src={logo} alt="#" className="" />
        </Col>
        <Col
          xs={8}
          md={10}
          className="d-flex   align-items-center justify-content-end footer-items"
        >
          {navitems.map((el, i) => (
            <Link to={el.to} className="nav-item px-2 px-md-4" key={i}>
              {el.name}
            </Link>
          ))}
          <Link to="contact us " className="px-2 px-md-4 contact">
            Contact
          </Link>
        </Col>
      </Row>
    </Wrapper>
  );
};
export default Footer;
