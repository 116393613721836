import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { AiOutlineLine } from "react-icons/ai";

import dotted from "../../images/dotted.svg";

const Wrapper = styled.div`
  padding: 35px 60px;
  overflow-x: hidden;
  .s-container {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08);
    border-radius: 14px;
    position: relative;
  }
  .s-title {
    font-family: Rozha One;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 43px;
    color: var(--primary-color);
    margin: 0;
  }

  .line {
    width: 50px;
    height: 4px;
    background: var(--primary-color);
  }
  .s-subtitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;

    color: #3c3c3c;
  }
  .image-container {
    position: relative;
    z-index: 5;
  }
  .color-div {
    position: absolute;
    width: 179px;
    height: 179px;
    left: -12px;
    top: -21px;

    background: #3490db;
    opacity: 0.2;
    border-radius: 20px;
    z-index: -1;
  }
  .color-div2 {
    position: absolute;
    width: 179px;
    height: 179px;
    right: -12px;
    top: -21px;

    background: #3490db;
    opacity: 0.2;
    border-radius: 20px;
    z-index: -1;
  }
  .seamless {
    width: 95%;
  }
  .dotted-image {
    position: absolute;
    bottom: 5px;
    right: -132px;
  }
  .dotted-image2 {
    position: absolute;
    top: 5px;
    left: -132px;
  }

  @media only screen and (max-width: 991px) {
    padding: 35px 50px;
    .s-subtitle {
      margin: 4px;
      font-size: 12px;
      line-height: 20px;
    }
  }
  @media only screen and (max-width: 767px) {
    .s-container {
      width: 80%;
      margin: 0 auto;
    }
    .seamless {
      width: 100%;
    }
    .s-title {
      text-align: center;
    }
    .line-container {
      text-align: center;
    }
    .s-subtitle {
      text-align: center;
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 27px;

      color: #3c3c3c;
    }
    .dotted-image {
      display: none;
    }
    .dotted-image2 {
      display: none;
    }
  }
  @media only screen and (max-width: 576px) {
    .s-container {
      width: 90%;
      margin: 0 auto;
    }
    .s-subtitle {
      font-size: 12px;
    }
  }
  @media only screen and (max-width: 520px) {
    .s-container {
      width: 100%;
      margin: 0 auto;
    }
  }
  @media only screen and (max-width: 400px) {
    padding: 35px 20px;
  }
`;
const SeamlessCustomSecure = (props) => {
  const { img, title, subtitle1, subtitle2, reverse } = props;
  return (
    <Wrapper>
      <Row
        className={`${subtitle2 && "align-items-center"}  s-container py-2 `}
        style={{ flexDirection: reverse && "row-reverse" }}
      >
        <Col md={6} className="image-container">
          <img src={img} alt="" className="seamless" />
          <div className={reverse ? "color-div2" : "color-div"}></div>
        </Col>

        <Col
          md={6}
          className={`${!subtitle2 && "py-md-4"} ${reverse && "px-md-4"} py-2`}
        >
          <h4 className="s-title">{title}</h4>
          <div className="line-container">
            {" "}
            <AiOutlineLine className="line" />
          </div>

          <p className="s-subtitle">{subtitle1}</p>
          <p className="s-subtitle">{subtitle2}</p>
        </Col>
        <div>
          {" "}
          <img
            src={dotted}
            alt=""
            className={reverse ? "dotted-image2 " : "dotted-image "}
          />
        </div>
      </Row>
    </Wrapper>
  );
};
export default SeamlessCustomSecure;
