import React from "react";
import styled from "styled-components";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img1 from "../../images/Sayvon View, Jerusalem.png";
import img2 from "../../images/All, Herzilya.png";
import img3 from "../../images/Sderot Hayovel, Jerusalem.png";
import { AiOutlineLine } from "react-icons/ai";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi";

const Wrapper = styled.div`
width:100%;
padding:40px;

tex-align:center;
.title{
    font-family: Rozha One;
font-style: normal;
font-weight: normal;
font-size: 34px;
line-height: 48px;

color: #B08561;
}
.line {
    width: 50px;
    height: 4px;
    background: var(--primary-color);
    margin-bottom:40px;
  }

.slider-img{
    width:100%:
    margin:0 auto;

}
.slider-title{
    font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 36px;



color: #3C3C3C;
}
.slider-subtitle{
    font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 27px;
/* identical to box height */


color: #848484;

}
.sliders-container{
    text-align:center;
    padding:5px 5px !important;
 
}
.sliderss{background: #FFFFFF;
box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08);
border-radius: 14px;
}
.slider-image{
    border-radius:15px;
}
.slick-dots li button{
   
}
.prev{
    position:absolute;
    left:0;
    z-index:1;
    top:50%;
    transform:translate(-50%);
    background:#BEA48E;
    border-radius:50%;
    padding:3px;

}
.next{
    position:absolute;
   
    z-index:1;
    top:50%;
    transform:translate(-50%);
    background:#BEA48E;

    border-radius:50%;
    padding:3px;
}

@media only screen and (max-width: 767px) {
    padding:40px 20px !important;
    .prev{
    position:absolute;
    left:0;
    z-index:1;
    top:40%;
    transform:translate(-50%);
    background:#BEA48E;
    border-radius:50%;
    padding:1px;

}
.next{
    position:absolute;
   
    z-index:1;
    top:40%;
    transform:translate(-50%);
    background:#BEA48E;

    border-radius:50%;
    padding:1px;
}
   .prev{
        display:none !important;
    }
    .next{
        display:none !important;
    }
}
   
 `;
function SampleNextArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      className="prev"
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <HiOutlineChevronLeft color="#fff" size="30" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      className="next"
      style={{ ...style, display: "block", right: "-35px" }}
      onClick={onClick}
    >
      <HiOutlineChevronRight color="#fff" size="30" />
    </div>
  );
}

const SliderSection = () => {
  const sliderArray = [
    {
      img: img1,
      title: "Sayvon View, Jerusalem",
      subtitle: "Developer - Africa Israel",
       to:"http://res.afi-g.com/en/cities/Jerusalem/view/Pages/default.aspx",
     
    },
    {
      img: img2,
      title: "All, Herzilya",
      subtitle: "Developer -  Brosh",
      to:"https://broshnir.co.il/portfolio/herzliya-all"
      
    },
    {
      img: img3,
      title: "Sderot Hayovel, Jerusalem",
      subtitle: "Developer - Carasso",
      to:"https://en.sderothayovel.co.il/"
    },
    
   {
      img: img1,
      title: "Sayvon View, Jerusalem",
      subtitle: "Developer - Africa Israel",
       to:"http://res.afi-g.com/en/cities/Jerusalem/view/Pages/default.aspx",
     
    },
    {
      img: img2,
      title: "All, Herzilya",
      subtitle: "Developer -  Brosh",
      to:"https://broshnir.co.il/portfolio/herzliya-all"
      
    },
    {
      img: img3,
      title: "Sderot Hayovel, Jerusalem",
      subtitle: "Developer - Carasso",
      to:"https://en.sderothayovel.co.il/"
    },
   {
      img: img1,
      title: "Sayvon View, Jerusalem",
      subtitle: "Developer - Africa Israel",
       to:"http://res.afi-g.com/en/cities/Jerusalem/view/Pages/default.aspx",
     
    },
    {
      img: img2,
      title: "All, Herzilya",
      subtitle: "Developer -  Brosh",
      to:"https://broshnir.co.il/portfolio/herzliya-all"
      
    },
    {
      img: img3,
      title: "Sderot Hayovel, Jerusalem",
      subtitle: "Developer - Carasso",
      to:"https://en.sderothayovel.co.il/"
    },
  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,

    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
       
          
        },
      },
      {
        breakpoint: 860,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Wrapper>
      <h2 className="title text-center">Projects & Availability</h2>
      <div className="text-center">
        <AiOutlineLine className="line" />
      </div>
      <Slider {...settings}>
        {sliderArray.map((el, i) => (
       
          <div key={i} className="sliders-container p-3 py-3" >
          <a href={el.to} target="_blank" rel="noreferrer" style={{textDecoration:"none"}}>

            <div className="sliderss p-3">
              {" "}
                 
              <img src={el.img} alt="" className="mx-auto w-100 slider-image" />
           
              <div style={{ height: "100px", paddingTop: "8px" }}>
                <h4 className="slider-title">{el.title}</h4>
                <span className="slider-subtitle">{el.subtitle}</span>
              </div>
                 
            </div>
             </a>
          </div>
         
        ))}
      </Slider>
    </Wrapper>
  );
};
export default SliderSection;
