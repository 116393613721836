import React from "react";
import styled from "styled-components";

import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import Banner from "../../Components/Banner/Banner";
import BetSquad from "../../Components/BetSquad/BetSquad";

import SliderSection from "../../Components/SliderSection/SliderSection";

const Wrapper = styled.div`
  overflow-x: hidden;
`;
const Home = () => {
  return (
    <Wrapper>
       <Navbar /> 
      <Banner />
      <BetSquad />
      <SliderSection />
      <Footer />
    </Wrapper>
  );
};
export default Home;
