import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { useMainContext } from "../Context/Context";
import { Link } from "react-router-dom";
import logo from "../../images/header-logo.svg";
import hamburger from "../../images/hamburger.svg";
import Sidebar from "./Sidebar";

const Wrapper = styled.div`
  padding: 0 40px;
  position: absolute;

  z-index: 1;
  top: 25px;
  width: 100%;
  .button {
    background: #fff;
    border: none;
    border-radius: 30px;
    font-size: 18px;
    line-height: 27px;
    color: #bea48e;
    padding: 8px 30px;
      font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 27px;
  }
  
  .nav-items:hover {
    color: #bea48e;
  }
  a {
    text-decoration: none;
    color: #fff;
    font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 27px;
/* identical to box height */


color: #FFFFFF;
  }

  .hamburger {
    display: none;
  }
   @media only screen and (max-width: 991px) {
     a{
       font-size:15px;
     }
      padding: 0 20px;
   }
  @media only screen and (max-width: 767px) {
    top: 15px;
    padding: 0 40px;
    .logo {
      text-align: center;
    }
    .logo img {
      width: 55px;
    }
    .hamburger {
      display: block;
      width: 30px;
    }
  }
  @media only screen and (max-width: 576px) {
    top: 15px;
      padding:0 10px;
    .logo {
      text-align: center;
    }
    .logo img {
      width: 45px;
    }
    .hamburger {
      display: block;
      width: 20px;
    }
  }
`;
const Navbar = () => {
  const { openSidebar } = useMainContext();

  return (
    <Wrapper>
      <Row>
        <Col sm={12} md={2} className="d-flex align-items-center px-4">
          <Row className="w-100">
            <Col
              xs={4}
              className="d-flex align-items-center d-md-none hamburger-container"
              onClick={openSidebar}
            >
              <img src={hamburger} alt="#" className="hamburger" />
            </Col>

            <Col xs={4} className="text-center logo">
              <img src={logo} alt="#" className="m-auto" />
            </Col>
            <Col xs={4} className="d-md-none"></Col>
          </Row>
        </Col>
        <Col
          md={10}
          className="d-md-flex d-none align-items-center justify-content-end "
        >
          <Link to={{ pathname:"https://portal.betbet.ai" }} target="_blank" className="px-4 ">B2 Homeowner Login</Link>
          <Link to="join" className="px-4 ">Join our B2 Affiliates</Link>
          <Link to="contact us " className="px-4">
            <button className="button">Contact</button>
          </Link>
        </Col>
      </Row>
      <Sidebar />
    </Wrapper>
  );
};
export default Navbar;
