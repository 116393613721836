import React from "react";
import styled from "styled-components";

import { Link } from "react-router-dom";
import { GrFormClose } from "react-icons/gr";
import { useMainContext } from "../Context/Context";

const Wrapper = styled.div`
  position: absolute;

  background: #fff;
  width: 300px;
  height: 100vh;
  top: -25px;
  left: 0;
  padding: 15px;

  .sidebar {
    height: 100vh;
  }

  .nav-items {
    color: #000;
    transition: all 0.5s;
    font-size: 15px;
    font-weight: 500;
  }
  .nav-items:hover {
    color: #bea48e;
  }
  .close:hover {
    color: #bea48e;
  }
  .nav-button {
    background: #bea48e;

    border: none;
    border-radius: 30px;
    font-size: 15px;
    line-height: 27px;
    color: #fff;
    padding: 8px 30px;
  }
  @media only screen and (max-width: 400px) {
    width: 100%;
  }
`;
const Sidebar = () => {
  const {
    isSidebarOpen,

    closeSidebar,
  } = useMainContext();
  const navitems = [
    { name: " B2 Homeowner Login", to: "login" },
    { name: " Join our B2 Affiliates", to: "join" },
  ];

  return (
    <Wrapper
      style={{
        transform: isSidebarOpen ? "translateX(0)" : "translateX(-100%)",
        transition: "all .5s",
      }}
    >
      <div className="d-flex flex-column  justify-content-center  sidebar">
        <div
          className="d-flex justify-content-end close"
          onClick={closeSidebar}
        >
          <GrFormClose size="35" />
        </div>
        <div className="d-flex flex-column py-5 my-5 align-items-center h-100 ">
          {navitems.map((el, i) => (
            <Link to={el.to} className="py-2 nav-items" key={i}>
              {el.name}
            </Link>
          ))}
          <Link to="contact us" className="py-2 nav-items">
            <button className=" nav-button">Contact</button>
          </Link>
        </div>
      </div>
    </Wrapper>
  );
};
export default Sidebar;
