import React from "react";
import styled from "styled-components";
import { Container, Row,  } from "react-bootstrap";
import image from "../../images/dotted.svg";
import seamless from "../../images/seamless.svg";
import secure from "../../images/secure.svg";
import custom from "../../images/custom.svg";
import SeamlessCustomSecure from "../SeamlessCustomSecure/SeamlessCustomSecure";

const Wrapper = styled.div`
  overflow-x: hidden;
  text-align: center;

  position: relative;

  padding-top: 90px;
  .b-container {
    width: 55%;
    margin: 0 auto;
    position: relative;
  }
  .b-title {
  
    font-family: "Rozha One", serif;
    font-family: Rozha One;
font-style: normal;
font-weight: normal;
font-size: 34px;
line-height: 48px;

color: #3C3C3C
  }
  .bet-squared {
    color: #b08561;
  }
  .b-subtitle {
    font-size: 18px;
    line-height: 27px;
    font-family: "Poppins", sans-serif;
    padding-top: 20px;
  }
  .side-dot {
    position: absolute;
    top: 45px;
    right: -20px;
  }
  .center-dot {
    position: absolute;
    top: -40px;
    left: -80px;
  }
  @media only screen and (max-width: 1199px) {
    .b-container {
      width: 63%;
    }
  }
  @media only screen and (max-width: 991px) {
    .b-container {
      width: 83%;
    }
  }
  @media only screen and (max-width: 767px) {
    .side-dot {
      display: none;
    }
    .center-dot {
      display: none;
    }
    .b-container {
      width: 95%;
    }
    .b-title {
      font-size: 28px;
    }
    .b-subtitle {
      font-size: 14px;
    }
  }
  @media only screen and (max-width: 520px) {
    .b-container {
      width: 98%;
    }
    .b-title {
      font-family: "Rozha One", serif;
      font-weight: 600;
      line-height: 30px;
    }
    .b-subtitle {
      font-size: 12px;
    }
  }
`;
const BetSquad = () => {
  const serviceArray = [
    {
      img: seamless,
      title: "Seamless",
      subtitle1:
        "Bet2 connects the homeowner to our affiliate Developer, Builder, Subcontractors, Suppliers, Architects, and Inspectors via a real-time platform enforcing workflow and delivery.",
    },
    {
      img: custom,
      title: "Custom",
      subtitle1:
        " Bet2 dynamic homebuyer website gives you access to homeowner communications, plans, resources and exclusive options at your fingertips to build and customize your dream home.",
      subtitle2: "Membership includes exclusive offers, products and services.",
      reverse: true,
    },
    {
      img: secure,
      title: "Secure",
      subtitle1:
        "Relax, Bet2 maintains secure, audited and neutral record keeping and communications acting a third party arbitrator.",
    },
  ];
  return (
    <>
      <Wrapper>
        <Container>
          <Row className="b-container">
            <h2 className="b-title">
              How does <span className="bet-squared">BET SQUARED</span> work?
            </h2>
            <p className="b-subtitle">
              Build and customize your new home using our unique platform that
              provides transparency and exclusive homebuilder technology and
              design offers. Enjoy continued complimentary membership to
              facilitate ease of maintenance, warranties and renovation.
            </p>
            <div>
              <img src={image} alt="" className="center-dot" />
            </div>
          </Row>
        </Container>
        <img src={image} alt="" className="side-dot" />
      </Wrapper>
      {serviceArray.map((el, i) => (
        <SeamlessCustomSecure {...el} key={i}/>
      ))}
    </>
  );
};
export default BetSquad;
