import React, { useState } from "react";
import styled from "styled-components";

//import video from "../../images/banner-video.mpg";
import banner from "../../images/banner.svg";
import poster from "../../images/poster.png";
import ReactPlayer from "react-player";

import play from "../../images/play.svg";

const Wrapper = styled.div`
  .video-container {
    position: relative;
    //height: 100vh;
    height: 610px;
  }
  .banner {
    width: 100%;
    object-fit: cover;
  }

  video {
    object-fit: cover;
  }
  img {
    width: 100%;
    object-fit: cover;
  }

  .banner-text {
    position: absolute;
    top: 130px;

    left: 50px;
  }
  .banner-title {
    color: #c4c4c4;
    font-size: 80px;
    font-family: "Poppins";
    //line-height: 35px;
    font-weight: normal;
    line-height: 60px;
    letter-spacing: -0.05em;
  }

  .banner-subtitle {
    position: absolute;
    margin-top: 25px;
    margin-left: 120px;
    color: #ffffff;
    font-size: 13px;
    line-height: 50px;
    letter-spacing: 0.1em;
    display: inline-block;
    //text-indent: -10px;
  }
  .banner-box-container {
    position: absolute;
    bottom: 30px;
    left: 25%;
    transform: translateX(0%);

    width: 550px;
    height: 310px;
  }

  .play-container {
    position: relative;
    height: 100%;
    width: 100%;
  }
  .play {
    position: absolute;
    width: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media only screen and (max-width: 991px) {
    .banner-box-container {
      //left: 20%;
      //bottom: 50px;
      //height: 250px;
    }
    .banner-title {
      //font-size: 60px;
    }
  }

  @media only screen and (max-width: 767px) {
    .banner-text {
      //top: 140px;
    }
    .banner-title {
      //font-size: 48px;
    }
    .banner-subtitle {
      //text-indent: 0px;
    }

    .banner-box-container {
      left: 50%;
      transform: translateX(-50%);
    }
  }
  @media only screen and (max-width: 550px) {
    .play-container {
      width: 100%;
      height: 100%;
    }

    .banner-box-container {
      bottom: 0px;
      width: 100%;
      color: #fff;
    }
  }
  @media only screen and (max-width: 480px) {
    .video-container {
      position: relative;
      //height: 93.5vh;
       height: 568px;
    }
    .banner-text {
      top: 180px;
      left: 30px;
    }
    .banner-title {
      font-size: 24px;
    }
    .banner-subtitle {
      font-size: 12px;
    }
    .banner-title {
      color: #bea48e;
      font-size: 40px;

      line-height: 32px;
    }
  }
  @media only screen and (max-width: 480px) {
    .banner-text {
      top: 220px;
      left: 30px;
    }
  }
`;
const Banner = () => {
  const [isPlay, setIsPlay] = useState(false);

  return (
    <Wrapper>
      <div className="video-container">
        <img
          src={banner}
          alt="Bet2"
          className="banner"
          width="100%"
          height="100%"
        />
        <div className="banner-text">
          <div className="banner-subtitle">the key to your dream home</div>
          <div className="banner-title">
            BET <br /> SQUARED
          </div>
        </div>

        <div className="banner-box-container">
          <div className="play-container">
            <ReactPlayer
              url="https://betsquared-homepage.s3.us-east-2.amazonaws.com/how+does+b2+work_.mp4"
              light={poster}
              width="100%"
              height="100%"
              preload="auto"
              playing={true}
              controls={true}
            ></ReactPlayer>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
export default Banner;
